











import { Vue, Component, Watch } from 'vue-property-decorator';
import NavComponent from './components/NavComponent.vue';
import LoginComponent from './components/LoginComponent.vue';
import NonAuthComponent from './components/NonAuthComponent.vue';
import { CurrentUser, MenuMeta, ShortRecord } from './store/models';
import { getLoginRedirect, getCurrentUser, setCurrentOrg } from './store/rest.service';
import { setCurrentUser, handleError, isFeatureEnabledForCurrentUser } from './lib/shared';
import * as features from './lib/feature-constants';
import * as _ from 'lodash';

@Component({
  components: {
    NavComponent,
    LoginComponent,
    NonAuthComponent,
  },
})
export default class App extends Vue {
  private checkingLogin: boolean = true;
  private isLoggedIn: boolean = false;
  private isLoggingIn: boolean = false;
  private isError: boolean = false;
  private darkModeRoutes: string[] = ['project-insights', 'insights'];
  private currentUser: CurrentUser|null = null;

  private menu: MenuMeta[] = [
                {
                  title: 'Insights',
                  href: '/insights',
                  feature: features.insightsMenu,
                },
                 {
                  title: 'Projects',
                  href: '/projects',
                },
                {
                  title: 'Payees',
                  href: '/payees',
                },
                {
                  title: 'Communities',
                  href: '/communities',
                },
                {
                  title: 'Clients',
                  href: '/clients',
                  feature: features.clientMenu,
                },
                {
                  title: 'Reports',
                  href: '/reports',
                },
          ];

  private getTheme(): string {
   return this.darkModeRoutes.includes(this.$route.name as string) ? 'theme--dark' : 'theme--light';
  }

  @Watch('currentUser', { deep: true })
  private onCurrentUserChanged(val: any, oldVal: any) {
    if (!_.isEqual(val, oldVal)) {
      // Check to see if the current org needs changing.
      const currentOrg = (val as CurrentUser).userinfo.currentOrg;
      const lastSelectedOrg = this.$store.getters['local/CURRENT_ORG'];
      if (currentOrg !== lastSelectedOrg && lastSelectedOrg !== '') {
        // Change the current organization.
        setCurrentOrg(lastSelectedOrg).then((response) => {
          this.loginCheck();
        }).catch((error) => {
          // If the user is not a member of the last organization, then just continue.
          this.setIsLoggedIn(val);
        });
      } else {
        this.setIsLoggedIn(val);
      }
      if (this.$route.path === "/insights" && !isFeatureEnabledForCurrentUser(features.insightsMenu, val)) {
        this.$router.push("/projects");
      }
    }
  }

  @Watch('$route', { immediate: true, deep: true })
  private onUrlChange(newVal: any) {

    // (window as any).Appcues.page();
    if (this.isErrorPage() || this.isNonAuthPage()) {
      this.mount();
    }
  }

  private setIsLoggedIn(currentUser: CurrentUser) {
    setCurrentUser(this.$store, currentUser);
    this.isLoggedIn = true;
  }

  private loginCheck() {
    getCurrentUser().then((response) => {
      this.currentUser = response;

      // Identify user to Reamaze
      // @ts-ignore
      _support["user"] = {
        id: this.currentUser.userinfo.identifier,
        email: this.currentUser.userinfo.userId,
        name: this.currentUser.userinfo.formattedName,
      };
      // @ts-ignore
      Reamaze.reload();

      // Identify the user to AppCues.
      // this.appCuesIdentify();
    }).catch((error) => {
      this.isLoggedIn = false;
      handleError([ error ], error, getLoginRedirect());
    }).finally(() => {
      this.checkingLogin = false;
      this.isLoggingIn = false;
    });
  }

  private loginSuccess(user: CurrentUser) {
    this.currentUser = user;
    this.checkingLogin = false;
    this.isLoggingIn = false;
  }

  private loginFailed() {
    this.isLoggedIn = false;
    this.checkingLogin = false;
    this.isLoggingIn = false;
  }

  private mounted() {
    this.mount();
  }

  private mount() {
    if (this.isErrorPage()) {
      this.isError = true;
    } else if (!this.isNonAuthPage()) {
      // Check if user is logged in and display the appropriate view.
      this.loginCheck();
    }
  }

  private isNonAuthPage(): boolean {
    return window.location.href.indexOf("/external/") > -1;
  }

  private isErrorPage(): boolean {
    return window.location.href.indexOf('/#/error') > -1;
  }

  private appCuesIdentify() {
    const userInfo = (this.currentUser as CurrentUser).userinfo;
    const company = (userInfo.memberOf as ShortRecord[]).find((x) => x.identifier === userInfo.currentOrg);
    (window as any).Appcues.identify(
      userInfo.identifier,
      {
        firstName: userInfo.givenNames,
        lastName: userInfo.familyName,
        companyName: company ? company.displayName : '',
        email: userInfo.userId,
      },
    );
  }
}
