








import { Component, Prop, Vue } from 'vue-property-decorator';
import AnnounceKit from "announcekit-vue";

@Component({
  components: {
    AnnounceKit,
  },
})
export default class AnnounceKitComponent extends Vue {
  @Prop() public isMobile: any;

  private getClass(): string {
    return 'ak-trigger announcekit-widget' + (this.isMobile ? ' announcekit-mobile' : '');
  }
}
