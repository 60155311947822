import {
  SearchParams,
  SortColumnMapping,
  ReferenceData,
  RefData,
  CurrentUser,
  MenuMeta,
  ShortRecord,
  ResourceSearchResult,
  BenefitMeta,
  TieredReferenceData,
  TieredRefDataValue,
  CategoryRefData,
  CategoryReferenceData,
  SearchResults,
  Aggregate,
  DiversityReferenceData,
  DiversityRefData,
  ReportDataTable,
  ReportData,
  TotalsAggregateValue,
  ReportDataTableRow,
  ColumnStyleItem,
  ProcurementBeneficiaryPayeesType,
  ProcurementPayeesType,
  DiversityRefDataCategory,
  DiversityRefDataValue,
} from "../store/models";
import { convertToTotalsAggregateValue } from "./aggregate";
import * as features from "../lib/feature-constants";

export function createResourceIdentifier(type: string, value: string): string {
  return (
    "urn:nisto-link:id:" +
    type +
    ":" +
    value
      .toLowerCase()
      .replace(/\s/g, "-")
      .replace(/[^a-z0-9/-]/g, "")
  );
}

export function getSortColumnMapping(sortMappings: SortColumnMapping[], fieldName: string): string {
  if (sortMappings.length > 0) {
    for (const mapping of sortMappings) {
      if (mapping.field === fieldName) {
        return mapping.sortColumn;
      }
    }
  }
  return fieldName;
}

export function getFormattedSearchTerms(searchTerms: string | null): string {
  if (searchTerms === null || searchTerms.trim() === "") {
    return "";
  } else {
    return searchTerms.toLowerCase();
  }
}

export function mapSearchParams(pagination: any, terms: string, sortMappings: SortColumnMapping[]): SearchParams {
  const { sortBy, descending, page, rowsPerPage } = pagination;
  return {
    page,
    ipp: rowsPerPage,
    sortby: getSortColumnMapping(sortMappings, sortBy),
    sortdir: descending ? "desc" : "asc",
    terms: getFormattedSearchTerms(terms),
  };
}

export function removeCommonProperties(item: any): any {
  delete item.verification;
  delete item.resourceInfo;
  delete item.spendInfo;
  delete item.index;
  delete item.diversityDeclarions;
  if (item.project) {
    delete item.project.poDate;
    delete item.project.contractorType;
    delete item.affiliatedVendors;
    // Remove optional description field if set to empty string.
    if (item.project.description === "") {
      delete item.project.description;
    }
    // Remove optional startDate field if set to empty string.
    if (item.project.startDate === "") {
      delete item.project.startDate;
    }
    delete item.project.hasProvisionalCreator;
  }
  if (item.vendor) {
    delete item.vendor.isSelfEmployedContractor;
    delete item.vendor.isActive;
    delete item.vendor.vendorState;
    delete item.vendor.hasProvisionalCreator;
    // Cannot update vendor.defaultExpenseClass if in live status.
    /*
        if (item.common.status === 'live') {
            delete item.vendor.defaultExpenseClass;
        }
        */
  }
  if (item.person) {
    if (item.person.phone === "") {
      delete item.person.phone;
    }
    if (item.person.description === "") {
      delete item.person.description;
    }
  }
  if (item.organization) {
    if (item.organization.description === "") {
      delete item.organization.description;
    }
    if (item.organization.orgAltName === "") {
      delete item.organization.orgAltName;
    }
  }
  delete item.common;
  return item;
}

export function formatCurrency(amount: string, roundedToNearestDollar: boolean = true): number | string {
  return isNaN(Number(amount)) ? amount : roundedToNearestDollar ? parseFloat(parseFloat(amount).toFixed(0)) : parseFloat(parseFloat(amount).toFixed(2));
}

export function formatCurrencyToString(amount: string, roundedToNearestDollar: boolean = true): string {
  return currency(formatCurrency(amount, roundedToNearestDollar), "$", roundedToNearestDollar ? 0 : 2, null);
}

export function formatAmountToString(amount: string): string {
  return currency(formatCurrency(amount), "", 0, null);
}

// Taken straight from vue2-filters and converted to TypeScript, so it can be used for HighCharts data tables where Filters are not accessible.
export function currency(value: any, symbol: any, decimals: any, options: any): any {
  const digitsRE: any = /(\d{3})(?=\d)/g;
  options = options || {};
  value = parseFloat(value);
  if (!isFinite(value) || (!value && value !== 0)) {
    return "";
  }
  symbol = symbol != null ? symbol : "$";
  decimals = decimals != null ? decimals : 2;
  const thousandsSeparator = options.thousandsSeparator != null ? options.thousandsSeparator : ",";
  const symbolOnLeft = options.symbolOnLeft != null ? options.symbolOnLeft : true;
  const spaceBetweenAmountAndSymbol = options.spaceBetweenAmountAndSymbol != null ? options.spaceBetweenAmountAndSymbol : false;
  let stringified = Math.abs(value).toFixed(decimals);
  stringified = options.decimalSeparator ? stringified.replace(".", options.decimalSeparator) : stringified;
  const numInt = decimals ? stringified.slice(0, -1 - decimals) : stringified;
  const i = numInt.length % 3;
  const head = i > 0 ? numInt.slice(0, i) + (numInt.length > 3 ? thousandsSeparator : "") : "";
  const numfloat = decimals ? stringified.slice(-1 - decimals) : "";
  symbol = spaceBetweenAmountAndSymbol ? (symbolOnLeft ? symbol + " " : " " + symbol) : symbol;
  symbol = symbolOnLeft ? symbol + head + numInt.slice(i).replace(digitsRE, "$1" + thousandsSeparator) + numfloat : head + numInt.slice(i).replace(digitsRE, "$1" + thousandsSeparator) + numfloat + symbol;
  const sign = value < 0 ? "-" : "";
  return sign + symbol;
}

export function formatToNumber(amount: string | number, dps: number): number | string {
  return amount ? (isNaN(Number(amount)) ? amount : parseFloat(parseFloat(amount.toString()).toFixed(dps))) : 0;
}

export function formatInt(amount: string): number {
  // return parseFloat(parseFloat(amount).toFixed(2));
  return parseFloat(parseFloat(amount).toFixed(0));
}

export function getDiversityRefDataCategory(data: DiversityReferenceData, code: string, category: string): DiversityRefDataCategory | undefined {
  for (const refData of (data.refData as DiversityRefData).values) {
    const refDataCategory: DiversityRefDataCategory | undefined = refData.categories.find((x) => x.categoryCode === category && x.code === code);
    if (refDataCategory !== undefined) {
      return refDataCategory;
    }
  }
  return undefined;
}

export function getDiversityRefDataValue(data: DiversityReferenceData, code: string, category: string): DiversityRefDataValue | undefined {
  for (const refData of (data.refData as DiversityRefData).values) {
    const refDataCategory: DiversityRefDataCategory | undefined = refData.categories.find((x) => x.categoryCode === category && x.code === code);
    if (refDataCategory !== undefined) {
      return refData;
    }
  }
  return undefined;
}

export function getDiversityCorrespondingCategory(data: DiversityReferenceData, code: string, category: string, correspondingCategory: string): DiversityRefDataCategory | undefined {
  const refDataValue: DiversityRefDataValue | undefined = getDiversityRefDataValue(data, code, category);
  return refDataValue !== undefined ? refDataValue.categories.find((x) => x.categoryCode === correspondingCategory) : undefined;
}

export function getDiversityNameFromCode(data: DiversityReferenceData, code: string, category: string) {
  const refDataCategory: DiversityRefDataCategory | undefined = getDiversityRefDataCategory(data, code, category);
  return refDataCategory !== undefined ? refDataCategory.itemNameForCategory : "";
}

export function getDiversityClassNameFromCode(data: DiversityReferenceData, code: string, category: string): string {
  const refDataValue: DiversityRefDataValue | undefined = getDiversityRefDataValue(data, code, category);
  return refDataValue !== undefined ? refDataValue.name : "";
}

export function getDisplayNameFromShortRecords(id: string, list: ShortRecord[] | null): string {
  if (list) {
    const shortRecord = list.find((x) => x.identifier === id);
    return shortRecord !== undefined ? (shortRecord.displayName ? shortRecord.displayName : "") : "";
  } else {
    return "";
  }
}

export function getRefTypeNameFromCode(data: ReferenceData, code: string) {
  for (const i of (data.refData as RefData).values) {
    if (i.code === code) {
      return i.name;
    }
  }
  return "Unknown";
}

export function getRefTypeNameandCategoryFromCode(data: CategoryReferenceData, code: string) {
  for (const i of (data.refData as CategoryRefData).values) {
    if (i.code === code) {
      return i.category + " > " + i.name;
    }
  }
}

export function getInvestmentCategoryForDisplay(benefitMeta: BenefitMeta, investmentTypes: TieredReferenceData): string {
  const investmentCategory = (investmentTypes as TieredReferenceData).refData.values.filter((category) => category.code === benefitMeta.benefitInvestmentCategory)[0];
  const subCategory = investmentCategory ? investmentCategory.subCategories.filter((subcategory) => subcategory.code === benefitMeta.benefitInvestmentArea)[0] : null;
  return (investmentCategory ? investmentCategory.name : "Unknown") + " > " + (subCategory ? (subCategory as TieredRefDataValue).name : "Unknown");
}

export function getAvatarInitials(orgName: string) {
  let initials = "";
  if (orgName) {
    for (const word of orgName.split(" ")) {
      if (initials.length < 2) {
        initials += word.substring(0, 1);
      }
    }
  }
  return initials;
}

export function redirectIfRequired(errorResponse: any, loginRedirect: string = "") {
  if (errorResponse.status === 401) {
    window.location.href = "/";
    /*}
    else if (errorResponse.status === 500) {
        const message: string = errorResponse.headers['x-message'] ? errorResponse.headers['x-message'].toString() : JSON.stringify(errorResponse.data);
        const errorUrl: string = '/#/error?message=' + message;
        window.location.href = (errorUrl);
        */
  } else if (errorResponse.status === 404 && loginRedirect !== "") {
    window.location.href = loginRedirect;
  }
}

export function handleExtraErrors(arrErrors: any[], extraErrors: any[], error: any) {
  const errors: any[] = error.response.data.errors;
  let isExtra: boolean = false;
  for (const e of errors) {
    if (e.validationError) {
      extraErrors.push([e.validationError.fieldDescription, e.validationError.message]);
      isExtra = true;
    }
  }
  if (!isExtra) {
    arrErrors.push(error);
  }
}

export function handleError(arrErrors: any[], error: any, loginRedirect: string = "") {
  if (error.response) {
    redirectIfRequired(error.response, loginRedirect);
  }
  arrErrors.push(error);
}

export function isRoleEnabled(currentUser: CurrentUser, role: string): boolean {
  return currentUser.userinfo.roles ? currentUser.userinfo.roles.includes(role) : false;
}

export function getEnabledFeatures(): string[] {
  return [
    features.clientMenu,
    features.communityEmploymentTables,
    features.contributionsByClientReport,
    features.employmentDiversityReports,
    features.employmentDiversityWidgetReports,
    features.employmentWidgets,
    features.indigenousEmploymentReports,
    features.allowImport,
    features.importClients,
    features.importEmployment,
    features.importPayees,
    features.importProjects,
    features.importExpense,
    features.revenueWidgets,
  ];
}

export function getEnabledFeaturesForCurrentUser(currentUser: CurrentUser): string[] {
  // return getEnabledFeatures();
  const additionalFeatures: string[] = [];
  if (currentUser.userinfo.roles && currentUser.userinfo.roles.indexOf("enable_all_access") > -1) {
    const addFeatures = [
      features.insightsMenu,
      "social-impact-corporate-enabled",
      "indigenous-impact-summary-enabled",
      "social-procurement-corporate-enabled",
      "social-procurement-community-summary-enabled",
      "diversity-in-employment-corporate-enabled",
      "tangible-net-benefits-summary-enabled",
      "speciality-canadian-council-enabled",
      "speciality-bcorp-certified-disabled",
      "speciality-equity-inclusion-enabled",
      "speciality-equity-inclusion-project-enabled",
      "compact-equity-inclusion-enabled",
      "dsc-project-indigenous-impact-disabled",
      "dsc-project-social-impact-disabled",
      "un-sustainable-development-enabled",
      "social-impact-location-enabled",
      "pehta-project-enabled",
      "pehta-corporate-enabled",
    ];
    for (const feat of addFeatures) {
      additionalFeatures.push(feat);
    }
  }
  if (currentUser.userinfo.roles && currentUser.userinfo.roles.indexOf("enable_upload") > -1) {
    additionalFeatures.push(features.allowImport);
    additionalFeatures.push(features.importClients);
    additionalFeatures.push(features.importPayees);
    additionalFeatures.push(features.importProjects);
    additionalFeatures.push(features.importExpense);
    additionalFeatures.push(features.importEmployment);
  }

  return currentUser.authProperties.licenseFeatures.concat(additionalFeatures);
}

export function isFeatureEnabled(feature: string, enabledFeatures: string[] | null = null): boolean {
  return enabledFeatures ? enabledFeatures.includes(feature) : false;
}

export function isFeatureEnabledForCurrentUser(feature: string, currentUser: CurrentUser): boolean {
  return isFeatureEnabled(feature, getEnabledFeaturesForCurrentUser(currentUser));
}

export function sanitizeCurrentUserForStorage(currentUser: CurrentUser): any {
  if (currentUser) {
    const personRecord = currentUser.personRecord;
    if (personRecord) {
      delete personRecord.common;
      delete personRecord.user;
      delete personRecord.person.givenNames;
      delete personRecord.person.phone;
      delete personRecord.person.familyName;
      delete personRecord.person.formattedName;
      delete personRecord.person.email;
      delete personRecord.phone;
      delete personRecord.phoneVerified;
      delete personRecord.sortKey;
      delete personRecord.userId;
      delete personRecord.userIdVerified;
    }
  }
  return currentUser;
}

export function setCurrentUser(store: any, currentUser: CurrentUser) {
  store.commit("session/SET_CURRENT_USER", sanitizeCurrentUserForStorage(currentUser));
  const currentOrg = currentUser.userinfo.currentOrg;
  store.commit("local/SET_CURRENT_ORG", currentOrg);
}

export function setPagingState(store: any, route: string, pagination: any) {
  delete pagination.totalItems;
  store.commit("session/SET_PAGING_STATE", { route, pagination });
}

export function getPagingState(store: any, route: string): any {
  const pagingState = store.getters["session/PAGING_STATE"];
  return pagingState.route === route ? pagingState.pagination : null;
}

export function getMenuItem(menu: any, name: string): MenuMeta {
  return menu.find((x: any) => x.title === name);
}

export function isShortRecordGeneralIC(record: ShortRecord): boolean {
  return (record.displayName as string).includes("(General)") || (record.displayName as string) === "Not Indigenous";
}

export function filterOutGeneralICs(result: ResourceSearchResult): ResourceSearchResult {
  result.searchResults.results = (result.searchResults.results as ShortRecord[]).filter((record) => !isShortRecordGeneralIC(record));
  return result;
}

export function filterOutStatusTypes(referenceData: ReferenceData): ReferenceData {
  referenceData.refData.values = referenceData.refData.values.filter((statusType) => statusType.code !== "voided");
  return referenceData;
}

export function aggregatesToShortRecords(aggregates: Aggregate[]): ShortRecord[] {
  return aggregates.map((i) => ({ identifier: i.facetDetail.identifier, displayName: i.facetDetail.displayName }));
}

export function arraysAreEqual(array1: string[], array2: string[]): boolean {
  return array1 && array2 && array1.length === array2.length && array1.every((value, index) => value === array2[index]);
}

export function zeroIfUndefined(item: number | undefined | null): number {
  if (item !== undefined) {
    if (item) {
      return item;
    } else {
      return 0;
    }
  } else {
    return 0;
  }
}

export function getSearchCriteriaIdentifier(criteria: string[] | string | ShortRecord[] | ShortRecord): string {
  if (criteria !== undefined) {
    if (Array.isArray(criteria)) {
      if (criteria.length > 0) {
        if ((criteria[0] as ShortRecord).identifier !== undefined) {
          return (criteria[0] as ShortRecord).identifier as string;
        } else {
          return criteria[0] as string;
        }
      } else {
        return "";
      }
    } else {
      if ((criteria as ShortRecord).identifier !== undefined) {
        return (criteria as ShortRecord).identifier as string;
      } else {
        return criteria as string;
      }
    }
  } else {
    return "";
  }
}

export function addPercentageToReportDataTable(dataTable: ReportDataTable, totalsAggregate: TotalsAggregateValue, totalRow: string[], columnStyle: ColumnStyleItem[] = []): ReportDataTable {
  for (let row of dataTable.rows) {
    row = addPercentageToReportDataTableRow(row, totalsAggregate);
    if (row.children) {
      for (let childRow of row.children) {
        childRow = addPercentageToReportDataTableRow(childRow, totalsAggregate);
      }
    }
  }
  if (totalRow) {
    dataTable.rows.unshift({ data: totalRow, columnStyle } as ReportDataTableRow);
  }
  return dataTable;
}

export function addPercentageToReportDataTableRow(row: ReportDataTableRow, totalsAggregate: TotalsAggregateValue): ReportDataTableRow {
  const lastIndex: number = row.data.length - 1;
  const rowString: string = row.data[lastIndex];
  const rowValue: number = parseInt(rowString.replace(/\$/g, "").replace(/,/g, ""), 10);
  const totalsAggregateValue: TotalsAggregateValue = convertToTotalsAggregateValue(rowValue, totalsAggregate.value, true);
  row.data[lastIndex] = totalsAggregateValue.valueFormatted + " (" + totalsAggregateValue.percentageFormatted + ")";
  return row;
}

export function sortResultsAlphabetically(results: ProcurementBeneficiaryPayeesType[] | ProcurementPayeesType[]): any {
  if ((results as ProcurementBeneficiaryPayeesType[])[0].beneficiary !== undefined || (results as ProcurementPayeesType[])[0].payee !== undefined) {
    const sortedResults: any[] = results.sort((n1, n2) => {
      if (n1.displayName > n2.displayName) {
        return 1;
      } else if (n1.displayName < n2.displayName) {
        return -1;
      } else {
        return 0;
      }
    });
    return sortedResults;
  } else {
    return results;
  }
}

export function getNistoProfilesConnectionClass(connectionStatus: string): string {
  switch (connectionStatus) {
    case "connected":
      return "nisto-profiles-connected";
    case "incomplete":
      return "nisto-profiles-incomplete";
    case "manual":
      return "nisto-profiles-manual";
    case "available":
      return "nisto-profiles-available";
    default:
      return "";
  }
}
